




















































































































import { Component, Vue } from "vue-property-decorator";
import { TestType } from "@/types/API/TestType";
import { namespace } from "vuex-class";
import {
  TEST_LIST_MODULE,
  UPLOAD_TEST_FILE_MODULE,
} from "@/constants/storeNames";
import Loader from "@/components/UI/Loader.vue";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";
import Table from "@/components/table/Table.vue";
import Modal from "@/components/UI/Modal.vue";
import { TableColumnConfigType } from "@/types/table/TableColumnConfigType";
import { TEST_STATUS_CONFIG } from "@/config/testStatusConfig";
import { TESTS_LIST_TABLE_CONFIG } from "@/config/testsListTableConfig";
import { app_routes } from "@/router/app_routes";
import { ObjectOf } from "@/types/ObjectOf";
import { TestStatusConfigType } from "@/types/TestStatusConfigType";
import { HttpErrorType } from "@/types/API/HttpErrorType";
import { STATUS_ACTIVE } from "@/constants/testStatuses";

const testList = namespace(TEST_LIST_MODULE);
const uploadTestFile = namespace(UPLOAD_TEST_FILE_MODULE);

@Component({
  components: {
    Modal,
    Loader,
    MaterialIcon,
    "base-table": Table,
  },
})
export default class AdminHome extends Vue {
  @testList.State tests!: TestType[];
  @testList.State("loading") testListLoading!: boolean;
  @uploadTestFile.State("loading") uploadFileLoading!: boolean;
  @uploadTestFile.State("errors") fileValidationErrors!: string[];

  public testsListPath = app_routes.admin_home.path;
  public testStatusConfig: ObjectOf<TestStatusConfigType> = TEST_STATUS_CONFIG;
  public testListTableConfig: TableColumnConfigType[] = TESTS_LIST_TABLE_CONFIG;
  public statusActive = STATUS_ACTIVE;

  public showModal = false;
  public file: File | null = null;
  public inputValue = "";

  mounted(): void {
    this.getData();
  }

  @uploadTestFile.Mutation setValidationErrors!: (
    errors: HttpErrorType
  ) => void;

  @uploadTestFile.Action
  sendFile!: (file: File) => Promise<void>;
  @testList.Action getData!: () => Promise<void>;

  public onToggleModalVisible(): void {
    this.showModal = !this.showModal;
    this.clearFile();
  }

  public onInputFile({ target }: { target: HTMLInputElement }): void {
    if (target.files === null) {
      return;
    }

    this.file = target.files[0];
    this.inputValue = target.value || "";
  }

  public clearFile(): void {
    this.file = null;
    this.inputValue = "";
    this.setValidationErrors({});
  }

  public submit(): void {
    if (this.file === null) {
      return;
    }

    this.sendFile(this.file);
  }
}
