








import { Prop, Component, Vue } from "vue-property-decorator";
import { TableColumnConfigType } from "@/types/table/TableColumnConfigType.ts";
import { TableDataType } from "@/types/table/TableDataType";

@Component
export default class TableData extends Vue {
  @Prop({ type: Object, required: true }) field!: TableColumnConfigType;
  @Prop({ type: Object, default: () => ({}) }) data!: TableDataType;
  @Prop({ type: String, default: "—" }) defaultValue!: string;

  public get getContent(): string | number {
    return this.data[this.field.key] || this.defaultValue;
  }
}
