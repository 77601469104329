
























import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";

@Component({
  components: { MaterialIcon },
})
export default class Modal extends Vue {
  @Prop({ type: Boolean, default: true }) showCloseBtn!: boolean;
  @Prop({ type: String, default: "" }) modalWidth!: string;

  @Emit()
  public close(): void {
    return;
  }
}
