










import { Component, Vue, Prop } from "vue-property-decorator";
import { TableColumnConfigType } from "@/types/table/TableColumnConfigType.ts";

@Component
export default class TableHead extends Vue {
  @Prop({ type: Array, required: true }) fields!: TableColumnConfigType[];
}
