






















import { Component, Vue, Prop } from "vue-property-decorator";
import TableData from "@/components/table/TableData.vue";
import TableHead from "@/components/table/TableHead.vue";
import { TableColumnConfigType } from "@/types/table/TableColumnConfigType.ts";
import { TableDataType } from "@/types/table/TableDataType";
import { ObjectOf } from "@/types/ObjectOf";
import { TestStatusConfigType } from "@/types/TestStatusConfigType";

@Component({
  components: {
    TableData,
    TableHead,
  },
})
export default class Table extends Vue {
  @Prop({ type: String, default: () => ({}) })
  rowClassConfig!: ObjectOf<TestStatusConfigType>;
  @Prop({ type: Array, required: true }) fields!: TableColumnConfigType[];
  @Prop({ type: String, default: "—" }) defaultValue!: string;
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  data!: TableDataType[];

  getRowClass(status: string): string {
    const config = this.rowClassConfig[status];
    return config ? config.rowClass : "";
  }
}
