import { v4 } from "uuid";
import { TableColumnConfigType } from "@/types/table/TableColumnConfigType";

export const TESTS_LIST_TABLE_CONFIG: TableColumnConfigType[] = [
  {
    id: v4(),
    name: "Версія",
    key: "version",
  },
  {
    id: v4(),
    name: "Дата створення",
    key: "created_at",
  },
  {
    id: v4(),
    name: "Статус",
    key: "status",
  },
  {
    id: v4(),
    name: "Дата зміни статусу",
    key: "updated_at",
  },
  {
    id: v4(),
    name: "",
    key: "id",
  },
];
